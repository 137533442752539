import SearchComponent from '../../search/component/search-component/search-component';
import './home.scss';
import { useEffect } from 'react'; 
import Pharmacy from '../component/pharmacy/pharmacy';
import OurService from '../component/our-service/our-service';
import OurApp from '../component/our-app/our-app';
import OurPartner from '../component/our-partner/our-partner';
import ContactUs from '../component/contact-us/contact-us';
import Offers from '../component/offers/offers';

const Home = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return(
        <div className="home">
            <div className="background-home">
                <div className="left"></div>
                <div className="right">
                    <div className="content">
                        <h3>
                            احجز مع أكفأ الأطباء <br />بكل سهولة ...
                        </h3>
                        <p>
                            دكتور سبيس يضم أكثر من 500 دكتور مؤهل بمختلف التخصصات                   
                        </p>
                    </div>
                </div>
            </div>

            <SearchComponent />
            {/* <Pharmacy /> */}
            <OurService />
            <Offers />
            <OurApp />
            <OurPartner />
            <ContactUs />
        </div>
    )
    
}

export default Home;
