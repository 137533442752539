import { Link } from 'react-router-dom';
import { useState } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { connect } from 'react-redux';

import './header.scss';
import NotificationsSection from '../../../notification/notification';

const Header = (props) => {
    const [navExpanded, setNavExpanded] = useState(false);
    
    const closeNav = () => {
        setNavExpanded(false);
    }

    const goToViolation = (id) => {
        const violation = document.getElementById(id); 
        window.scrollTo({
          top:violation.offsetTop,
          behavior:"smooth"
      });
    };

    return (
        <div className="header">
            <Navbar expand="lg" onToggle={(value) => setNavExpanded(value)} expanded={navExpanded}>
                <Navbar.Brand>
                    <div className="logo">
                        <Link to="/home">
                            <img src={process.env.PUBLIC_URL + '/assets/images/logo.svg'} alt="Dr.Space" />
                        </Link>
                    </div>
                </Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse>
                    <Nav>
                        <div className="content">
                            <ul className="list">
                                <li onClick={closeNav}><Link to="/home">الرئيسية</Link></li>
                                <li onClick={closeNav}><Link to="/search-medical">قائمة المراكز</Link></li>
                                <li onClick={closeNav}><Link to="/offers">العروض</Link></li>
                                {/* <li onClick={closeNav}><Link to="/search-labs">المختبرات</Link></li> */}
                                {/* <li onClick={closeNav}>الصيدلية</li> */}
                                <li onClick={() => window.location.replace("/#contactUs")}>اتصل بنا</li>
                                {
                                    !props.user ?
                                        <li className="registerButton" onClick={closeNav}>
                                            <Link to="/login">سجل الآن</Link>
                                        </li>
                                        :
                                        <li className="my-account-button">
                                            <img src={process.env.PUBLIC_URL + '/assets/images/account.svg'} alt="account" />
                                            <Link to="/my-account">حسابى</Link>
                                        </li>
                                }
                                {props?.user && <li><NotificationsSection /></li>}
                            </ul>
                            {/* <div className="language">
                                <span>En</span>
                                <img src={process.env.PUBLIC_URL + '/assets/images/america.svg'} alt="English" />
                            </div> */}
                        </div>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user
    }
}

export default connect(mapStateToProps)(Header);
