import './offer-details.scss';

import { useState, useEffect } from 'react';
import { Skeleton } from 'antd';
import { useParams } from "react-router-dom";
import { getOfferDetailsAPi } from '../../data/api/offers-api';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
import { useLocation } from "react-router-dom";
import { Modal } from 'antd';
import Login from '../auth/page/login/login';
const OfferDetails = ({user}) => {
    const history = useHistory();

    const params = useParams();
    const { id } = params;
    const [loading, setLoading] = useState(false);
    const [offer, setOffer] = useState();
    const [selectedImage,setSelectedImage] = useState(0);
    const [appointmentsBody, setAppointmentsBody] = useState({});
    const [dayIndex, setDayIndex] = useState(0);
    const [appointments, setAppointments ] = useState([]);
    const [selectedTime, setSelectedTime] = useState();
    const [showModal, setShowModal] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const search = useLocation().search;
    const video = new URLSearchParams(search).get('video');

    useEffect(() => {
        setLoading(true);
        getOfferDetailsAPi(id).then(
            res => {
                setOffer(res.data.data);
                const appointmentArray = res?.data?.data?.appointments.filter(element => element.allow_use)
                setAppointments(appointmentArray)
                const result = appointmentArray.reduce(function (r, a) {
                    r[a.day] = r[a.day] || [];
                    r[a.day].push(a);
                    return r;
                }, Object.create(null));
                const resultKeys = Object.keys(result).sort();
                const resultSorted = {}
                resultKeys.forEach(key => {
                    resultSorted[key] = result[key];
                })
                setAppointmentsBody(resultSorted);
                setLoading(false)
            }
        )
    }, [])

    const getNextDayAppointments = () => {
        if (dayIndex !== Object.keys(appointmentsBody).length -1) {
            const counter = dayIndex + 1;
            setDayIndex(counter);
        }
    }

    const getPrevDayAppointments = () => {
        if (dayIndex !== 0) {
            const counter = dayIndex - 1;
            setDayIndex(counter);
        }
    }

    const onSelectTime = (value) => {
        setSelectedTime(value)
    }

    const onSelectOffer = (value) => {
        localStorage.removeItem('offer')
        if (value) {
            localStorage.setItem('offer', JSON.stringify(value))
        }
    }

    // const navigateToDoctor = () => {
    //     if (offer?.appointments[0]?.visit_type === 2) {
    //         history.push(`/doctor/${offer?.doctor?.uuid}/${offer?.uuid}?video=true`);
    //     } else {
    //         history.push(`/doctor/${offer?.doctor?.uuid}/${offer?.id}`)
    //     }
    // }

    const navigateToLab = () => {
        history.push(`/lab/${offer?.owner?.uuid}/${offer?.id}`)
    }

    const onBookingDoctor = () => {
        setButtonDisabled(false)
        if (!selectedTime) {
            setButtonDisabled(true)
            return;
        }
        if (user) {
            history.push("/booking-doctor/" + selectedTime + '/' + offer?.doctor?.uuid + `?video=${video}&offer_id=${offer?.id}`);
        } else {
            setShowModal(true)
        }
    }

    const closeModalHandler = () => {
        setShowModal(false);
    }

    return(
        <div className='offer-details-container'>
            <Skeleton active={true} loading={loading} avatar>
                <div className='left'>
                    <div className='images'>
                        <div className='sub-images'>
                            {offer?.images.map((image, index) => (
                                <img onClick={() => setSelectedImage(index)} key={index} className={selectedImage === index ? 'active' : ''} src={image.image} alt="" />
                            ))}
                        </div>
                        <div className='main-image'>
                            <img src={offer?.images[selectedImage].image} alt="" />
                        </div>
                    </div>
                    <div className='service-supply'>
                        <img src={process.env.PUBLIC_URL + '/assets/images/test.svg'} alt="" />
                        <h3>مقدم الخدمة</h3>
                    </div>
                    <div className='medical-center'>
                        <img src={offer?.owner?.image} alt="" />
                        <div>
                            <p>إسم المركز الصحى</p>
                            <h3>{offer?.owner?.name}</h3>
                        </div>
                    </div>
                    {offer?.details && <div className='service-supply info'>
                        <img src={process.env.PUBLIC_URL + '/assets/images/info.svg'} alt="" />
                        <h3>لمعلوماتك</h3>
                    </div>}
                    {offer?.details && <div className='medical-center'>
                        <p>{offer?.details}</p>
                    </div>}
                </div>
                <div className='right'>
                    <h2>{offer?.name}</h2>
                    <h2>{offer?.offer_specialty?.name}</h2>
                    <div className='price'>
                        <div>
                            <span>{offer?.price} ر.س</span>
                            <span>{offer?.price_after_discount} ر.س</span>
                        </div>
                        <div className='discount'>
                            <span>خصم {offer?.discount_percentage}%</span>
                        </div>
                    </div>
                    <div className='address'>
                        <img src={process.env.PUBLIC_URL + '/assets/images/address.svg'} alt="" />
                        <span>{offer?.owner?.address}</span>
                    </div>
                    {offer?.type === 'medical_centers' && <div className='doctors'>
                        <h3>الأطباء المتاحين للعرض</h3>
                        <div>
                            <img src={offer?.doctor?.image} alt={offer?.doctor?.name} />
                            <span>{offer?.doctor?.name}</span>
                        </div>
                    </div>}
                    {offer?.type === 'labs' && <div className='doctors labs' onClick={navigateToLab}>
                        <h3>حجز التحاليل الموجودة داخل العرض</h3>
                    </div>}

                    {offer?.type !== 'labs' ? <div className="booking-info online">
                        <span style={{"fontWeight": "bold"}}>اختار موعد الحجز</span>
                        <div className="appointments">
                        {
                            Object.keys(appointmentsBody).length > 0 ?
                                <div className="header-book">
                                    <img src={process.env.PUBLIC_URL + '/assets/images/arrow-left.svg'} onClick={getPrevDayAppointments}  alt="" />
                                        {
                                            new Date(Object.keys(appointmentsBody)[dayIndex]).getDate() === new Date().getDate() &&
                                            new Date(Object.keys(appointmentsBody)[dayIndex]).getMonth() === new Date().getMonth() ?
                                            <p>اليوم</p> : <p>{Object.keys(appointmentsBody)[dayIndex]}</p>
                                        }
                                    <img src={process.env.PUBLIC_URL + '/assets/images/arrow-left.svg'} onClick={getNextDayAppointments}  alt="" />
                                
                                </div>: ''
                            }
                            <div className="timing">
                                {appointments?.length > 0  ?
                                    Object.values(appointmentsBody)[dayIndex]?.map(element => (
                                        <span
                                            className={selectedTime === element.uuid ? 'active' : ''} 
                                            onClick={() => {
                                                onSelectTime(element.uuid)
                                                onSelectOffer(offer)
                                            }}
                                        >
                                            {element.start_time}
                                        </span>
                                    ))
                                    :
                                    <p>لا يوجد حجوزات</p>
                                }
                            </div>
                            <div className="button">
                                <button onClick={onBookingDoctor}>
                                    احجز
                                    {/* <Link to="/booking-doctor/1">احجز</Link> */}
                                </button>
                            </div>
                        </div>
                    </div> : ''}
                </div>
                <Modal title="" visible={showModal} footer={null} maskClosable={false} onCancel={closeModalHandler} width={600}>
                    <Login url={`/booking-doctor/${selectedTime}/${id}`} />
                </Modal>
            </Skeleton>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user
    }
}

export default connect(mapStateToProps)(OfferDetails);