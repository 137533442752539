import { useState, useRef } from 'react';
import { contactUsApi } from '../../../../data/api/global-api';
import './contact-us.scss';
import { message } from 'antd';

const ContactUs = () => {
    const [nameError, setNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [descriptionError, setDescriptionError] = useState(false);
    const name = useRef();
    const email = useRef();
    const description = useRef();

    const checkNameError = (e) => {
        if (e.target?.value.trim()) {
            setNameError(false);
        } else{
            setNameError(true);
        }
    }

    const checkEmailError = (e) => {
        if (e.target?.value.trim()) {
            setEmailError(false);
        } else{
            setEmailError(true);
        }
    }

    const checkMessageError = (e) => {
        if (e.target?.value.trim()) {
            setDescriptionError(false);
        } else{
            setDescriptionError(true);
        }
    }

    const handelSubmit = () => {
        if (!name.current.value.trim()) {
            setNameError(true);
        } else {
            setNameError(false);
        }
        if (!email.current.value.trim()) {
            setEmailError(true);
        } else {
            setEmailError(false);
        }
        if (!description.current.value.trim()) {
            setDescriptionError(true);
            return;
        } else {
            setDescriptionError(false);
        }

        const body = {
            name: name.current.value,
            email: email.current.value,
            message: description.current.value
        }

        contactUsApi(body).then(
            res => {
                name.current.value = '';
                email.current.value = '';
                description.current.value = '';
                message.success('تم إرسال رسالتك بنجاح');
            }
        ).catch(error => {
            message.error(Object.values(error.response.data?.errors)[0][0] || 'حدث خطأ ما');
        })
    }

    return(
        <div className="contact-us" id="contactUs">
            <div className="contact-header">
                <h4>اتصل بنا</h4>
                <h2>أبق على تواصل</h2>
                <span></span>
                <p>يسعدنا تواصلكم معنا واستقبال جميع ملاحظاتكم من خلال تعبئة النموذج</p>
            </div>
            <div className="contact-data">
                <div className="right">
                    <div className="element">
                        <div>
                            <img src={process.env.PUBLIC_URL + '/assets/images/location.svg'} alt="" />
                            <h4>العنوان:</h4>
                        </div>
                        <p>الرياض المملكة العربية السعودية</p>
                    </div>
                    <div className="element">
                        <div>
                            <img src={process.env.PUBLIC_URL + '/assets/images/telephone.svg'} alt="" />
                            <h4>الهاتف:</h4>
                        </div>
                        <p style={{direction: 'ltr'}}>8001111052</p>
                    </div>
                    <div className="element">
                        <div>
                            <img src={process.env.PUBLIC_URL + '/assets/images/email.svg'} alt="" />
                            <h4>البريد الالكترونى:</h4>
                        </div>
                        <p>info@drspace.com</p>
                    </div>
                </div>
                <div className="left">
                    <input ref={name} type="text" name="" placeholder="الأسم" onInput={checkNameError} />
                    <span className="error">{nameError &&'هذا الحقل مطلوب'}</span>
                    <input ref={email} type="email" name="" placeholder="البريد الألكترونى" onInput={checkEmailError} />
                    <span className="error">{emailError &&'هذا الحقل مطلوب'}</span>
                    <textarea ref={description} rows="4" cols="" placeholder="نص رسالتك" onInput={checkMessageError}></textarea>
                    <span className="error">{descriptionError &&'هذا الحقل مطلوب'}</span>
                    <button onClick={handelSubmit} type="">إرسال رسالة</button>
                </div>
            </div>
        </div>
    )
}

export default ContactUs;