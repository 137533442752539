import './our-service.scss';

const OurService = () => {
    return(
        <div className="our-service">
            <div className="service-header">
                <h4>خدماتنا</h4>
                <h2>ماذا نقدم لك</h2>
                <span></span>
            </div>
            <div className="service-data">
                <div className="right"></div>
                <div className="left">
                    <div>
                        <img src={process.env.PUBLIC_URL + '/assets/images/timer.svg'} alt="" />
                        <div className="content">
                            <h3>استشارات طبية صوتية مرئية</h3>
                            <p>
                                جدول حجوزاتك وتصفح أكفأ الأطباء المتخصصين
                            </p>
                        </div>
                    </div>
                    <div>
                        <img src={process.env.PUBLIC_URL + '/assets/images/pills.svg'} alt="" />
                        <div className="content">
                            <h3>استشارات حضورية للمركز</h3>
                            <p>
                            احجز مواعيدك واستعرض أقرب المراكز لموقعك والعروض
                            </p>
                        </div>
                    </div>
                    <div>
                        <img src={process.env.PUBLIC_URL + '/assets/images/booking-visit.svg'} alt="" />
                        <div className="content">
                            <h3>تحاليل طبية</h3>
                            <p>
                                تصفح أقرب المختبرات مع عروضها واحتفظ بالنتائج والتقارير
                            </p>
                        </div>
                    </div>
                    <div>
                        <img src={process.env.PUBLIC_URL + '/assets/images/doctor.svg'} alt="" />
                        <div className="content">
                            <h3>قيّم</h3>
                            <p>
                                شارك تجربتك واطلع على تجارب مستخدمين حقيقين
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OurService;