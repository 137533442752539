import { Link } from 'react-router-dom';
import './footer.scss';

const Footer = () => {

    return (
        <div className="footer">
            <p>جميع الحقوق محفوظة ل دكتور سبيس &copy; 2021</p>
            <div className="social">
                <div className="icons">
                    {/* <img src={process.env.PUBLIC_URL + 'assets/images/twitter.svg'} alt="Dr.space twitter" /> */}
                </div>
                <div className="apps">
                    <div>
                        <ul>
                            <li><Link to="/terms">سياسة المستخدم والخصوصية</Link></li>
                            <li><Link to="/about-us">من نحن</Link></li>
                        </ul>
                    </div>
                    {/* <div>
                        <img src={process.env.PUBLIC_URL + '/assets/images/google-play.svg'} alt="Dr.space Google Play" />
                        <img src={process.env.PUBLIC_URL + '/assets/images/app-store.svg'} alt="Dr.space App Store" />
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default Footer;