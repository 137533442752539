import "./search-component.scss";
import { Tabs } from 'antd';
import SearchDoctor from "../search-doctor/search-doctor";
import OnlineSearch from "./online-search/online-search";
import SearchLab from "../search-lab/search-lab";

const { TabPane } = Tabs;


const SearchComponent = () => {

    const callback = (key) => {
        console.log(key);
    }

    return (
        <div className="search-component">
            <div className="content">
                <Tabs onChange={callback} type="card">
                    <TabPane tab="موعد مع دكتور" key="1">
                        <SearchDoctor />
                    </TabPane>
                    <TabPane tab="إستشارة اونلاين" key="2">
                        <OnlineSearch />
                    </TabPane>
                    <TabPane tab="إستشارة فورية" key="3">
                        <div className="consult">
                            <span>للإستشارات العاجلة يرجى تحميل التطبيق</span>
                            <div className="consulty-images">
                                <img src={process.env.PUBLIC_URL + '/assets/images/google-play.svg'} alt="Dr.space Google Play" />
                                <img src={process.env.PUBLIC_URL + '/assets/images/app-store.svg'} alt="Dr.space App Store" />
                            </div>
                        </div>
                    </TabPane>
                    {/* <TabPane tab="رعاية منزلية" key="3">
                        <OnlineSearch />
                    </TabPane> */}
                    <TabPane tab="رعاية منزلية" key="4">
                        <SearchLab />
                    </TabPane>
                </Tabs>
            </div>
        </div>
    )
}

export default SearchComponent;