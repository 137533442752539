import './lab-data.scss';
import { Rate } from 'antd';

const LabData = ({doctors}) => {
    return (
        <div className="doctors-data">
            <div className="doctor-image">
                <img src={doctors?.image} alt="" />
            </div>
            <div className="doctor-name">
                {doctors?.name ? <p>{doctors?.name}</p> : ''}
                {doctors?.description ? <p>{doctors?.description}</p> : '' }
                {doctors?.labSpecialties[0]?.name ? <p>{doctors?.labSpecialties[0]?.name}</p> : ''}
            </div>
            <div className="doctor-rate" style={{justifyContent: 'flex-start'}}>
                {/* <div className="rate">
                    <img src={process.env.PUBLIC_URL + '/assets/images/like.svg'} alt="" />
                    <img src={process.env.PUBLIC_URL + '/assets/images/eye.svg'} alt="" />
                    <p>150.435 مشاهدات</p>
                </div>
                <div className="rate">
                    <Rate allowHalf disabled defaultValue={5} />
                </div> */}
                {/* <div className="rate">
                    {doctors?.medical_center?.image ? <img style={{"width": '50px', 'height': '50px', 'borderRadius': '50%'}} src={doctors?.medical_center?.image} alt="" /> : ''}
                    <div className="medical-logo">
                        <p>إسم المركز الصحى</p>
                        <p>{doctors?.medical_center?.name}</p>
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default LabData;