import React from 'react';

import './404.scss';

const Handle404 = () => {
    return (
        <div class="handle-404">
            <img src={process.env.PUBLIC_URL + '/assets/images/not-found.svg'} alt="not-found" />
        </div>
    )

}

export default Handle404;