import { Select } from 'antd';
import { Input } from 'antd';
import './search-inputs.scss';
import { medicalSpecialties, governorates, getCities, insuranceCompaniesApi, vaccinationApi } from '../../../../data/api/search-api';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';


const { Option } = Select;

const SearchInputs = ({getDoctorsMethod}) => {
    const [ specialties, setSpecialties ] = useState([]);
    const [ governments, setGovernments ] = useState([]);
    const [ insuranceCompanies, setInsuranceCompanies ] = useState([])
    const [ cities, setCities ] = useState([]);
    const [ city, setCity ] = useState(null);
    const [ government, setGovernment ] = useState(null);
    const [ specialty, setSpecialty ] = useState(null);
    const [ doctor, setDoctor ] = useState(null);
    const [ insuranceComp, setInsuranceComp ] = useState(null);

    useEffect(() => {
        getMedicalSpecialties();
        getGovernment();
        getInsuranceCompanies();
    }, []);

    const getMedicalSpecialties = () => {
        vaccinationApi().then(
            res => {
                res?.data?.data.unshift({id: null, name: 'الكل'})
                setSpecialties(res.data.data);
            }
        )
    }

    const getInsuranceCompanies = () => {
        insuranceCompaniesApi().then(
            res => {
                res?.data?.data.unshift({id: null, name: 'الكل'})
                setInsuranceCompanies(res.data.data);
            }
        )
    }

    const getGovernment = () => {
        governorates().then(
            res => {
                res?.data?.data.unshift({id: null, name: 'الكل'})
                setGovernments(res.data.data);
            }
        )
    }

    const onGovernmentChange = (id) => {
        setGovernment(id);
        getCities({'g_id': id}).then(
            res => {
                res?.data?.data.unshift({id: null, name: 'الكل'})
                setCities(res.data.data)
            }
        )
    }

    return (
        <div className="search-inputs">
        <div className="title">
            <h3>إحجز خدمة</h3>
        </div>
            
            <div className="content-input">
                <div className="">
                    <label htmlFor="">نوع الخدمة</label>
                    <Select style={{ width: '100%' }} placeholder="اختر الخدمة" onChange={(value) => setSpecialty(value)}>
                        {specialties.map((element) => (
                            <Option key={element.id} value={element.id}>{element.name}</Option>
                        ))}
                    </Select>
                </div>
                <div className="">
                    <label htmlFor="">المدينة</label>
                    <Select style={{ width: '100%' }} placeholder="المدينة" onChange={(value) => onGovernmentChange(value)}>
                        {governments.map(element => (
                            <Option key={element.id} value={element.id}>{element.name}</Option>
                        ))}
                    </Select>
                </div>
                <div className="">
                    <label htmlFor="">الحى</label>
                    <Select style={{ width: '100%' }} placeholder="الحى" onChange={(value) => setCity(value)}>
                        {cities.map(element => (
                            <Option key={element.id} value={element.id}>{element.name}</Option>
                        ))}
                    </Select>
                </div>
                <div className="">
                    <label htmlFor="">التأمين</label>
                    <Select placeholder="التأمين" style={{ width: '100%' }} onChange={(value) => setInsuranceComp(value)}>
                        {insuranceCompanies.map(element => (
                            <Option key={element.id} value={element.id}>{element.name}</Option>
                        ))}
                    </Select>
                </div>
                <div className="">
                    <label htmlFor="">أو أكتب إسم الخدمة</label>
                    <Input placeholder="أكتب إسم الخدمة" onChange={(event) => setDoctor(event.target.value)} />
                </div>

                <div className="">
                    <button className="button" onClick={() => getDoctorsMethod(specialty, government, city, insuranceComp, doctor)}>إبحث</button>
                </div>
            </div> 
            
        </div>
    )
}

export default SearchInputs;