import axios from "./base-api";

export const getAllReservationApi = () => 
    axios({
        method: 'get',
        url: '/reservations',
    })

export const bookReservationApi = (data, id) => {
    const body = {
        name: data.name,
        mobile: data.mobile,
        for_other_person: data.for_other_person,
        attachment: data.attachment,
        offer_id: +data.offer_id,
        insured: data.insured,
        payment_method: data.payment_method,
        customer: {
            email: data.customer.email,
            givenName: data.customer.givenName,
            surname: data.customer.surname
        },
        billing: {
            street1: data.billing.street1,
            city: data.billing.city,
            state: data.billing.state,
            country: data.billing.country,
            postcode: data.billing.postcode
        }
    }

    return axios({
        method: 'post',
        url: `/doctor-appointments/${id}/book`,
        data:body
    })
}

export const generateChannel = (userId, channel) => (
    axios({
        method: 'post',
        url: `/agora/token-sdk?uid=${userId}&channel=${channel}`
    })
)

export const makeOrderApi = (data) => {
    const body = {
        doctor_reservation_id: data.doctor_id,
        lab_id: data.lab_id,
        lab_specialty_ids: data.specialties,
        payment_method: data.payment_method,
        attendance_date: data.attendance_date,
        attend_at: data.attend_at,
        offer_id: data.offer_id,
        customer: {
            email: data.customer.email,
            givenName: data.customer.givenName,
            surname: data.customer.surname
        },
        billing: {
            street1: data.billing.street1,
            city: data.billing.city,
            state: data.billing.state,
            country: data.billing.country,
            postcode: data.billing.postcode
        }
    }
    return axios({
        method: 'post',
        url: `/lab-orders`,
        data:body
    })
}

export const getAllLabOrdersApi = (type) => 
    axios({
        method: 'get',
        url: `/lab-orders?status=${type}`,
    })


export const getReservationReportsApi = () =>
    axios({
        method: 'get',
        url: 'reservations/reports'
    })


export const showReportApi = (labId) => 
    axios({
        method: 'get',
        url: `lab-orders/${labId}`
    })

export const showReservationReportApi = (doctorId) => 
    axios({
        method: 'get',
        url: `reservations/reports/${doctorId}`
    })