import './offers-list.scss';

import { Input, Select, Skeleton, Empty } from 'antd';
import { useState, useEffect } from 'react';
import { governorates } from '../../data/api/search-api';
import { getAllOffersApi } from '../../data/api/offers-api';
import { useHistory } from 'react-router-dom';

const { Option } = Select;

const OffersList = () => {
    const history = useHistory();
    const [ governments, setGovernments ] = useState([]);
    const [ offers, setOffers ] = useState([]);
    const [ meta, setMeta ] = useState();
    const [ loading, setLoading ] = useState(false);
    const [ searchValue, setSearchValue ] = useState('');
    const [ page, setPage ] = useState(1);

    useEffect(() => {
        getGovernment();
        getOffers();
    }, []);


    const getOffers = (search = '') => {
        setPage((page) => ++page);
        setLoading(true);
        getAllOffersApi(search, page).then(
            res => {
                setLoading(false);
                setOffers([...offers, ...res.data?.data]);
                setMeta(res.data?.meta)
            }
        )
    }

    const getGovernment = () => {
        governorates().then(
            res => {
                setGovernments(res.data.data);
            }
        )
    }

    const onSearch = () => {
        getOffers(searchValue);
    }

    return(
        <div className="offers-container">
            <div className='headers'>
                <Input placeholder="ما الذى تبحث عنه؟" onInput={(e) => setSearchValue(e.target.value)} />
                <Select placeholder="اختر المدينة">
                    {governments.map(element => (
                        <Option key={element.id} value={element.id}>{element.name}</Option>
                    ))}
                </Select>
                <button className="button" onClick={onSearch}>ابحث</button>
            </div>
            <div className='background'>
                <div className='background-image' style={{backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/images/offer-background.svg'})`}}></div>
            </div>
            <Skeleton className="" active={true} loading={loading} avatar>
                {offers.length ? <div className='offers-cards'>
                <h3>أقوى العروض لأجلك</h3>
                <div className='cards'>
                    {offers.map((offer, index) => (
                        <div className="element" key={index} onClick={() => history.push(`/offer/${offer?.uuid}`)}>
                            <div className="top-header" style={{backgroundImage: `url(${offer?.images[0].image})`}}>
                                <p>{Math.round(offer?.discount_percentage)}%</p>
                                <img src={process.env.PUBLIC_URL + '/assets/images/star.svg'} alt="" />
                                <div className='overlay-background'></div>
                                <div className="doctor-name">
                                    {offer?.owner?.image && <img style={{borderRadius: '50%'}} src={offer?.owner?.image} alt="" />}
                                    <div>
                                        <p>{offer?.owner?.name} - {offer?.owner?.address}</p>
                                        <p>{offer?.doctor?.address}</p>
                                    </div>
                                    {/* <div>
                                        <Rate allowHalf disabled defaultValue={5} />
                                        <p>التقييم من 125 زائر</p>
                                    </div> */}
                                </div>
                            </div>
                            <div className="content">
                                <div className="specialty">
                                    {offer?.name}
                                </div>
                                <div className="operation">
                                    جلسة واحدة
                                </div>
                                <div className="price">
                                    <p>
                                        <span>{offer?.price} ريال</span>
                                        <span>وفر {offer?.price - offer?.price_after_discount} ريال</span>
                                    </p>
                                    <div className='bottom-part'>
                                        <span>{offer?.price_after_discount} ريال</span>
                                        <button type="">حجز</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                {meta?.current_page * 15 < meta?.total ?<div className="more">
                    <button onClick={() => getOffers(searchValue)}>عرض المزيد</button>
                </div> : '' }
            </div> 
            :
            <Empty
                description={
                <span className="font-Lsmall weight-600 text-theme-color">
                    لا توجد عروض
                </span>
                }
            />
            }
            </Skeleton>

        </div>
    )
}

export default OffersList;