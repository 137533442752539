import { BrowserRouter as Router } from 'react-router-dom';
import { useSelector } from 'react-redux';

import './App.scss';
import Header from './ui/layout/component/header/header';
import Footer from './ui/layout/component/footer/footer';
import Routes from './routes/route';
import "bootstrap/dist/css/bootstrap.min.css";
import { connect } from 'react-redux';
import BaseApi from './data/api/base-api';
import Route from './routes/index';
import PaymentProcess from './ui/booking-doctor/payment-process';

function App() {
  const { token } = useSelector(state => state);
  if (token) {
    BaseApi.defaults.headers.common["authorization"] = `Bearer ${token.replaceAll("\"", '')}`;
  }

  return (
    <Router>
        <div className="App">
          {window.location.href.includes('mobile') && window.location.href.includes('payment') ?
            <Route path="/payment-process/:checkout_id/:environment/:method/mobile" component={PaymentProcess} /> :
            <>
              <Header />
              <main className="page-content">
                <Routes />
              </main>
              <Footer />
            </>
          }
        </div>
    </Router>
  );
}

const mapStateToProps = state => {
  return {
      token: state.token
  }
}

export default connect(mapStateToProps)(App);
