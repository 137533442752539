import './offers.scss';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { getAllOffersApi } from '../../../../data/api/offers-api';
import { useHistory } from 'react-router-dom';

const Offers = () => {
    const history = useHistory();
    const [offers, setOffers] = useState([]);

    useEffect(() => {
        getOffers();
    }, [])

    const getOffers = () => {
        getAllOffersApi('', 1).then(
            res => {
                setOffers(res.data?.data?.splice(0, 3));
            }
        )
    }

    return(
        <div className="offers">
            <div className="offers-header">
                <h4>العروض</h4>
                <h2>أفضل الخدمات بأفضل الأسعار</h2>
                <span></span>
                <p>تصفح أقوى العروض والخصومات لأكفأ الأطباء وأفضل الخدمات والمراكز</p>
                <Link to="/offers"> تصفح جميع العروض <img src={process.env.PUBLIC_URL + '/assets/images/arrow.svg'} alt="" /></Link>
            </div>
            <div className="offers-data">
                {offers?.map((offer, index) => (<div className="element" key={index} onClick={() => history.push(`/offer/${offer?.uuid}`)}>
                    <div className="top-header" style={{backgroundImage: `url(${offer?.images[0]?.image})`}}>
                        <p>{offer?.discount_percentage}% خصم</p>
                    </div>
                    <div className="content">
                        <div className="doctor-name">
                            {offer?.owner?.image && <img style={{borderRadius: '50%'}} src={offer?.owner?.image} alt="" />}
                                <p>{offer?.owner?.name} - {offer?.owner?.address}</p>
                            {/* <div>
                                <Rate allowHalf disabled defaultValue={5} />
                                <p>التقييم من 125 زائر</p>
                            </div> */}
                        </div>
                        <div className="specialty">
                            {offer?.name}
                        </div>
                        <div className="operation">
                            {offer?.offer_specialty?.name}
                        </div>
                        <div className="price">
                            <p>
                                <span>{offer?.price} ر.س</span>
                                <span>{offer?.price_after_discount} ر.س</span>
                            </p>
                            <button type="">عرض التفاصيل</button>
                        </div>
                    </div>
                </div>))}

            </div>
        </div>
    )
}

export default Offers;