import './doctors-result.scss';
import { Skeleton, Empty } from "antd";
import { Rate } from 'antd';
import { useHistory } from 'react-router';


const DoctorsResult = ({doctors, loading, meta, getDoctorsMethod}) => {
    const history = useHistory();

    const getMoreDoctors = () => {
        getDoctorsMethod(meta?.current_page + 1);
    }

    const navigateToDoctor = (event, id) => {
        event.stopPropagation();
        if (window.location.href.includes('video')) {
            history.push(`/doctor/${id}?video=true`);
        } else {
            history.push(`/doctor/${id}`)
        }
    }

    const navigateToMedical = (event, id) => {
        event.stopPropagation();
        history.push(`/medical-center/${id}`)
    }

    return(
        <div className="doctors-result">
            <div className="header-title">
                <p className="">كل الأطباء ({meta?.total})</p>
                {/* <div className="sort col-6">
                    <label for="">الترتيب</label>
                    <Select defaultValue="lucy" style={{ width: '100%' }}>
                        <Option value="jack">Jack</Option>
                        <Option value="lucy">Lucy</Option>
                        <Option value="disabled" disabled>
                            Disabled
                        </Option>
                        <Option value="Yiminghe">yiminghe</Option>
                    </Select>
                </div> */}
            </div>

            <div className="">
                <Skeleton className="" active={true} loading={loading} avatar>
                    {doctors?.length == 0 ? (
                        <div className="">
                            <Empty
                                description={
                                <span className="font-Lsmall weight-600 text-theme-color">
                                    لا يوجد أطباء
                                </span>
                                }
                            />
                        </div>
                    ) : (
                        doctors?.map((doctor) => (
                            <div key={doctor?.id} className={doctor?.is_featured && doctor?.isFeaturedElement? 'is-featured element' : 'element'} onClick={(event) => navigateToDoctor(event, doctor?.uuid)}>
                                <div className="doctor-image">
                                    <div className="images">
                                        <img className="doctor-image-element" src={doctor?.image} alt={doctor?.name} />
                                        {doctor?.medical_center?.image ? <img className="medical-center-image" onClick={(event) => navigateToMedical(event, doctor?.medical_center?.uuid)} src={doctor?.medical_center?.image} alt="" /> : ''}
                                    </div>
                                </div>
                                <div className="doctor-data">
                                    <div className="doctor-name">
                                        <p>{doctor?.name}</p>
                                        {/* {doctor.clinic_allow ? <img src={process.env.PUBLIC_URL + '/assets/images/audio-call.svg'} alt="" /> : ''} */}
                                        {doctor?.video_allow ? <img src={process.env.PUBLIC_URL + '/assets/images/video-call.svg'} alt="" /> : ''}
                                    </div>
                                    { doctor?.description ?<div className="doctor-description">
                                        <p>{doctor?.description}</p>
                                    </div> : ''}
                                    {doctor?.medical_specialties && doctor?.medical_specialties[0]?.name ? <div className="doctor-description">
                                        <img src={process.env.PUBLIC_URL + '/assets/images/examine.svg'} alt="" />
                                        <p>{doctor?.medical_specialties[0]?.name}</p>
                                    </div> : ''}
                                    {doctor?.medical_center?.name ? <div className="medical" onClick={(event) => navigateToMedical(event, doctor?.medical_center?.uuid)}>
                                        <p>إسم المركز الصحى</p>
                                        <p>{doctor?.medical_center?.name}</p>
                                    </div> : ''}
                                </div>
                                <div className="doctor-rate">
                                    {/* <div className="rate">
                                        <Rate allowHalf disabled defaultValue={5} />
                                    </div> */}
                                    <div className="price">
                                        <img src={process.env.PUBLIC_URL + '/assets/images/money.svg'} alt="" />
                                        {window.location.href.includes('video') ? <p>سعر الكشف {doctor?.video_fees} ر.س</p> : <p>سعر الكشف {doctor?.clinic_fees} ر.س</p>}
                                    </div>
                                    <div className="price" style={{"display": "flex", "flexWrap": "nowrap"}}>
                                        <a target="_blank" href={`https://maps.google.com/?q=${doctor?.latitude},${doctor?.longitude}`}>
                                            <img src={process.env.PUBLIC_URL + '/assets/images/address.svg'} alt="" />
                                            <p>{doctor?.address}</p>
                                        </a>
                                    </div>
                                    <div className="booking">
                                        <span>أحجز</span>
                                    </div>
                                </div>
                            </div>
                        ))
                    )}
                    {meta?.current_page * 15 < meta?.total ?<div className="more">
                        <button onClick={getMoreDoctors}>عرض المزيد</button>
                    </div> : '' }
                </Skeleton>
                
            </div>

        </div>
    )
}

export default DoctorsResult;