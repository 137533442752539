import './orders-list.scss';
import { useEffect, useState } from 'react';
import { getAllLabOrdersApi, getAllReservationApi } from '../../../../data/api/reservation-api';
import { Skeleton, Empty } from "antd";
import { useHistory } from 'react-router-dom';

const OrdersList = ({type}) => {
    const history = useHistory();
    const [reservations, setReservations] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getReservations();
    }, [])

    const getReservations = () => {
        setLoading(true)
        getAllLabOrdersApi(type).then(
            res => {
                setLoading(false)
                setReservations(res.data.data)
            }
        )
    }

    const statusDiv = (element) => {
        if (element.status == 2) {
            return (<div className="status">بانتظار التأكيد</div>)
        } else if(element.status == 3) {
            return (<div className="status confirmed">تم التأكيد</div>)
        }
    }

    const navigateToLab = (lab) => {
        localStorage.setItem('lab', JSON.stringify(lab));
        history.push(`/search-labs`);
    }


    return (
        <div className="ordersList-container">
            <div className="doctor-content" style={{'paddingBottom': '20px'}}>
            <Skeleton className="" active={true} loading={loading} avatar>
            {reservations.length === 0 ? (
                <div className="">
                    <Empty
                        description={
                        <span className="font-Lsmall weight-600 text-theme-color">
                            لا يوجد حجوزات
                        </span>
                        }
                    />
                </div>
                ): 
                reservations.map(element => (
                        <div className="element">
                        <div style={{'display': 'flex', 'flexWrap': 'wrap'}}>
                            {element.lab?.image && <div className="doctor-image">
                                <div className="images">
                                    <img src={element?.lab?.image} alt="" />
                                </div> 
                            </div> }
                            <div className="doctor-data" style={{'flexGrow': '1'}}>
                                <div className="doctor-name">
                                    <p>{element?.name}</p>
                                </div>
                                <div className="doctor-description">
                                    <img src={process.env.PUBLIC_URL + '/assets/images/lab.svg'} alt="" />
                                    <p>{element?.items?.length} تحاليل</p>
                                </div>
                                <div className="medical">
                                    <p style={{direction: 'ltr'}}>{element?.attend_at}</p>
                                </div>
                            </div>
                            <div className="doctor-address">
                                <div>
                                    <img src={process.env.PUBLIC_URL + '/assets/images/credit-card.svg'} alt="" />
                                    <p>{element?.total}</p>
                                </div>
                                {element?.lab?.address && <div>
                                    <a target="_blank" href={`https://maps.google.com/?q=${element?.lab?.latitude},${element?.lab?.longitude}`}>
                                        <img src={process.env.PUBLIC_URL + '/assets/images/address.svg'} alt="" />
                                        <p>{element?.lab?.address}</p>
                                    </a>
                                </div> }
                                {type == 1 && <div className="options-container">
                                    {
                                        element.status === 1 ?
                                            <div className="options confirm">
                                                تم التأكيد
                                            </div> :
                                            <div className="option cancel">
                                                بانتظار التأكيد
                                            </div>
                                    }
                                </div>}
                            </div>
                        </div>
                    </div>
                ))
                }
                </Skeleton>
            </div>
        </div>
    )
}

export default OrdersList;