import './our-app.scss';

const OurApp = () => {
    return(
        <div className="our-app">
            {/* <div className="app-data">
                <div className="right"></div>
                <div className="left">
                    <div className="content">
                        <h2>قم بتحميل التطبيق <br /> الآن</h2>
                        <div className="images">
                            <img src={process.env.PUBLIC_URL + '/assets/images/black-google-play.svg'} alt="" />
                            <img src={process.env.PUBLIC_URL + '/assets/images/black-app-store.svg'} alt="" />
                        </div>
                        <div style={{'display': 'flex', 'justifyContent': 'center'}}>
                            <p style={{margin: '0'}}>تتبع الحجوزات وتصفح أفضل الأطباء والعروض</p>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    )
}

export default OurApp;