import React from 'react';
import { useParams } from "react-router-dom";
import './lab-info.scss';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

const LabInfo = ({doctors, offerId}) => {
    const params = useParams();
    const { id } = params;
    const history = useHistory();
    const [lab, setLab] = useState('');
    const [buttonIsDisabled, setButtonIsDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [savedAnalysis, setSavedAnalysis]= useState([]);
    const [test, setTest] = useState(false);
    
    useEffect(() => {
        setLab(() => JSON.parse(localStorage.getItem('lab')));
        checkAddedAnalysis();
    }, [doctors]);

    const checkAddedAnalysis = () => {
        if (doctors?.labSpecialties.length && lab?.analysis?.length) {
            lab.analysis?.forEach(item => {
                doctors.labSpecialties.forEach(element => {
                    if (element.id === item.id ) {
                        setSavedAnalysis((pre) => [...pre, element]);
                        element.is_included = true;
                    }
                })
                console.log(savedAnalysis);
            })
            checkButtonIsDisabled();
        }
    }

    const checkButtonIsDisabled = () => {
        if (doctors.labSpecialties?.some(item => item.is_included)) {
            setButtonIsDisabled(true);
        } else {
            setButtonIsDisabled(false);
        }
    }

    const makeOrder = () => {
        localStorage.setItem('savedAnalysis', JSON.stringify(savedAnalysis));
        localStorage.removeItem('offer');
        localStorage.setItem('offerId', offerId)
        history.push(`/booking-cart/${id}`)
    }

    const addAnalysis = (element, index) => {
        let checkArray = [];
        checkArray = savedAnalysis.map(item => item.id === element.id);
        if (!checkArray) {
            if (checkArray.includes(true)) {
                element.is_included = false;
                setSavedAnalysis((pre) => pre.splice(index, 1));
            } else {
                element.is_included = true;
                setSavedAnalysis((pre) => [...pre, element]);
            }
        } else {
            if (checkArray.includes(true)) {
                element.is_included = false;
                setSavedAnalysis((pre) => pre.splice(index, 1));
            } else {
                element.is_included = true;
                setSavedAnalysis((pre) => [...pre, element]);
            }
        }
        setTest((pre) => !pre);
    }


    return(
        <React.Fragment>
            <div className="doctors-info">
                {savedAnalysis.length ? <div className='fixed'>
                    <button onClick={makeOrder}>تأكيد الحجز</button>
                </div> : ''}
                <div className="left">
                    <div className="">
                        <div className="doctor-header">
                            <img src={process.env.PUBLIC_URL + '/assets/images/info-circle.svg'} alt="" />
                            <p>نبذة عن المركز</p>
                        </div>
                        <div className="info">
                            <p>{doctors?.description}</p>
                        </div>
                    </div>

                    <div className="">
                        <div className="doctor-header">
                            <img src={process.env.PUBLIC_URL + '/assets/images/examine.svg'} alt="" />
                            <p> خدمات المركز</p>
                        </div>
                        <div className="specialties">
                            {doctors?.labSpecialties.map((element, index) => (
                                <span key={index}>{element.name}</span>
                            ))}
                        </div>
                    </div>

                    <div className="">
                        <div className="doctor-header">
                            <img src={process.env.PUBLIC_URL + '/assets/images/examine.svg'} alt="" />
                            <p> شركات التأمين</p>
                        </div>
                        <div className="specialties">
                            {doctors?.medical_center?.insuranceCompanies.map(element => (
                                <span className="">{element.name}</span>
                            ))}
                        </div>
                    </div>
                    <div className="booking-info online data" style={{flexDirection: 'row'}}>
                        <a target="_blank" href={`https://maps.google.com/?q=${doctors?.latitude},${doctors?.longitude}`}>
                            <img src={process.env.PUBLIC_URL + '/assets/images/address.svg'} alt="" />
                            <p>{doctors?.address}</p>
                        </a>
                    </div>
                </div>

                <div className="right" style={{"paddingLeft": "0px"}}>
                    <div className="booking-info">
                        <span>التحاليل</span>
                    </div>
                    {doctors?.labSpecialties.length ? <div className="vaccine-container">
                        {
                            doctors?.labAnalysis.map((element, index) => (
                                <div key={index} className="vaccine-card">
                                    <div className="left">
                                        <img src={process.env.PUBLIC_URL + '/assets/images/vaccine.svg'} alt="" />
                                        <div className="vaccine-content">
                                            <span>{element?.labSpecialty?.name}</span>
                                            {element?.note ? <span className="note">{element?.note}</span>: ''}
                                            <div className={element?.offer?.id ? 'discount-price' : ''}>
                                                <span>{element.price} ر.س</span>
                                                {element?.offer?.id && <span>{element?.offer?.price_after_discount} ر.س</span>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="right">
                                        {!element.is_included ? 
                                        <p 
                                            onClick={() => addAnalysis(element, index)}
                                            className={element?.offer?.id ? 'lab-offer' : ''}
                                        >
                                            <span>+</span> 
                                            إضافة
                                        </p> 
                                        : 
                                        <p 
                                            onClick={() => addAnalysis(element, index)}
                                            style={{backgroundColor: '#AD9FBF'}}
                                        >
                                            <span>+</span>
                                            تمت الأضافة
                                        </p>}
                                    </div>
                                </div>
                            ))
                        }
                        {savedAnalysis.length ? <div className="make-order">
                            <button onClick={makeOrder}>تأكيد الحجز</button>
                        </div> : ''}
                    </div> : ''}
                </div> 
            </div>
            <Backdrop className='backdrop' open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>

        </React.Fragment>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user
    }
}

export default connect(mapStateToProps)(LabInfo);