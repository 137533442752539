import MedicalResult from "../component/medical-result/medical-result";
import FilterComponent from "../component/filter-component/filter-component"
import SearchInputs from "../component/search-inputs/search-inputs"
import './search-medical.scss';
import { useLocation } from "react-router-dom";
import { useEffect, useState } from 'react';
import { searchMedicalApi } from '../../../data/api/search-api';
import { useHistory } from 'react-router-dom';
import { Drawer } from 'antd';
import { Modal } from 'antd';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const SearchMedical = () => {
    const history = useHistory();
    let url = window.location.href;
    let query = useQuery();
    let specialty = query.get('specialty');
    let government = query.get('government');
    let city = query.get('city');
    let doctor = query.get('doctor');
    let insuranceCompany = query.get('insuranceCompany');
    const [subMedicalSpecialties, setSubMedicalSpecialties] = useState([]);
    const [availableDate, setAvailableDate] = useState(null);
    const [sex, setSex] = useState('');
    const [titles, setTitles] = useState([]);
    const [doctorsList, setDoctorsList] = useState([]);
    const [price, setPrice] = useState([]);
    const [is_loading, setIsLoading] = useState(false);
    const [showDrawer, setShowDrawer] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [meta, setMeta] = useState();

    useEffect(() => {
        getDoctors();
        window.scrollTo(0, 0);
    }, [subMedicalSpecialties, availableDate, sex, titles, price, pageNumber]);

    const onShowDrawer = () => {
        setShowDrawer(true)
    };

    const onClose = () => {
        setShowDrawer(false)
    };

    const closeModalHandler = () => {
        setShowModal(false);
    }

    const onSearchClicked = () => {
        setShowModal(true)
    }

    const getNextPage = (page) => {
        setPageNumber(page);
    }

    const getDoctors = (allDays = false) => {
        if (allDays) {
            setAvailableDate([]);
        }
        const requestBody = {}
        if (specialty) requestBody['medical_specialty_id'] = specialty;
        if (government) requestBody['governorate_id'] = government;
        if (city) requestBody['city_id'] = city;
        if (doctor) requestBody['d_name'] = doctor.trim();
        if (insuranceCompany) requestBody['i_company'] = insuranceCompany;
        if (subMedicalSpecialties.length > 0) requestBody[`sub_medical_specialties`] = subMedicalSpecialties; 
        if (availableDate?.length > 0) requestBody['appointmentDates'] = availableDate; 
        if (allDays) requestBody['appointmentDates'] = 'all'; 
        if (sex) {
            requestBody['sex'] = sex;
        } 
        if (titles?.length > 0) requestBody['titles'] = titles;
        if (price?.length > 0) requestBody['c_price'] = price.join(',') + `${url.includes('video') ? ',video_fees' : ''}`;
        if (url.includes('video')) {
            requestBody['s_allow'] = 'video';
        }
        requestBody['page'] = pageNumber;
        setIsLoading(true);
        searchMedicalApi(requestBody).then(
            res => {
                setIsLoading(false);
                setDoctorsList(res.data.data)
                setMeta(res.data.meta)
            }
        )
    }

    const getDoctorsList = (specialtyValue, governmentValue, cityValue, insuranceCompanyValue, doctorValue, page = null) => {
        specialty = specialtyValue;
        government = governmentValue;
        city = cityValue;
        insuranceCompany = insuranceCompanyValue;
        doctor = doctorValue;
        if (page) {
            history.push(`/video-call?specialty=${specialty}&doctor=${doctor}`)
        } else {
            history.push(`/search-medical?specialty=${specialty}&government=${government}&city=${city}&doctor=${doctor}&insuranceCompany=${insuranceCompanyValue
            }`)
        }
        getDoctors();
    }

    const onFilterChanges = (subMedical=[], dateArray=[], allDaysDate=false, gender=null, titlesArray=[], priceValue=[]) => {
        if (subMedical.length > 0) {
            setSubMedicalSpecialties([...subMedical]);
        }

        if (dateArray?.length) {
            setAvailableDate([...dateArray]);
        }

        if (allDaysDate) {
            getDoctors(true);
        }

        setSex(gender)
        if (gender) {
        }

        if (titlesArray.length) {
            setTitles([...titlesArray]);
        }

        if (priceValue.length) {
            setPrice([...priceValue]);
        }
    }


    return(
        <div className="search-result">
                <SearchInputs getDoctorsMethod={getDoctorsList} />
                {/* <div className="search-button" onClick={onSearchClicked}>
                    <img src={process.env.PUBLIC_URL + '/assets/images/search.svg'} alt="" />
                    <span>إبحث</span>
                </div> */}
            <div className="result">
                <FilterComponent viewDrawer={false} specialtyId={specialty} changeFilter={onFilterChanges} />
                <div className="search-title" onClick={onShowDrawer}>
                    <img src={process.env.PUBLIC_URL + '/assets/images/filter.svg'} alt="" />
                    <h3 className="">حدد بحثك</h3>
                </div>
                <MedicalResult doctors={doctorsList} getDoctorsMethod={getNextPage} loading={is_loading} meta={meta} />
            </div>

            <Drawer
                placement="right"
                closable={false}
                onClose={onClose}
                visible={showDrawer}
                getContainer={true}
                style={{ position: 'absolute' }}
            >
                <FilterComponent viewDrawer={true} specialtyId={specialty} changeFilter={onFilterChanges} />
            </Drawer>

            <Modal title="" visible={showModal} footer={null} maskClosable={false} onCancel={closeModalHandler} width={600}>
                <SearchInputs getDoctorsMethod={getDoctorsList} />
            </Modal>
        </div>
    )
}

export default SearchMedical