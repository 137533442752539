import { useParams } from "react-router-dom";
import { useEffect, useState } from 'react';
import { getDoctorApi } from '../../../data/api/search-api';
import DoctorData from "../component/doctor-data/doctor-data";
import './view-doctor.scss';
import DoctorInfo from "../component/doctor-info/doctor-info";


const ViewDoctor = () => {
    const params = useParams();
    const { id } = params;
    const { offerId } = params;
    const [doctor, setDoctor] = useState()

    useEffect(() => {
        getDoctor();
        window.scrollTo(0, 0)
    }, [])

    const getDoctor = () => {
        getDoctorApi(id).then(
            res => {
                setDoctor(res.data.data)
            }
        )
    }

    return(
        <div className="view-doctor">
            <DoctorData doctors={doctor} />
            <DoctorInfo offerId={offerId} doctors={doctor} />
        </div>
    )
}

export default ViewDoctor;