import { useParams } from "react-router-dom";
import { useEffect, useState } from 'react';
import { getLabApi } from '../../../data/api/search-api';
import LabData from "../component/lab-data/lab-data";
import './view-lab.scss';
import LabInfo from "../component/lab-info/lab-info";
import { Skeleton } from "antd";

const ViewLab = () => {
    const params = useParams();
    const { id } = params;
    const { offerId } = params;
    const [lab, setLab] = useState()
    const [loading, setLoading] = useState(false) 

    useEffect(() => {
        getDoctor();
        window.scrollTo(0, 0)
    }, [])

    const getDoctor = () => {
        setLoading(true)
        getLabApi(id, offerId).then(
            res => {
                setLab(res.data.data)
                setLoading(false)
                localStorage.setItem('lab', JSON.stringify(res?.data?.data));

            }, error => {
                setLoading(false)
            }
        )
    }

    return(
        <Skeleton className="" active={true} loading={loading} avatar>
            <div className="view-doctor">
                <LabData doctors={lab} />
                <LabInfo offerId={offerId} doctors={lab} />
            </div>
        </Skeleton>
    )
}

export default ViewLab;