import './medical-info.scss';
import { Tabs } from 'antd';
import DoctorsResult from '../doctors-result/doctors-result';

const { TabPane } = Tabs;

const MedicalInfo = ({doctors}) => {
    return(
        <div className="medical-info-container">
            <Tabs defaultActiveKey="1">
                <TabPane tab="معلومات المركز" key="1">
                    <div className="doctors-info">
                        <div className="left">
                            <div className="">
                                <div className="doctor-header">
                                    <img src={process.env.PUBLIC_URL + '/assets/images/info-circle.svg'} alt="" />
                                    <p>خدمات المركز</p>
                                </div>
                                <div className="info">
                                    <p>{doctors?.description}</p>
                                </div>
                            </div>

                            <div className="">
                                <div className="doctor-header">
                                    <a target="_blank" href={`https://maps.google.com/?q=${doctors?.latitude},${doctors?.longitude}`}>
                                        <img src={process.env.PUBLIC_URL + '/assets/images/address.svg'} alt="" />
                                        <p>{doctors?.address}</p>
                                    </a>
                                </div>
                            </div>

                            <div className="">
                                <div className="doctor-header">
                                    <img src={process.env.PUBLIC_URL + '/assets/images/examine.svg'} alt="" />
                                    <p>فروع المركز الصحى</p>
                                </div>
                                <div className="specialties">
                                    <span className="">{doctors?.governorate?.name}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </TabPane>
                <TabPane tab="أطباء المركز" key="2">
                    <DoctorsResult medicalCenter={doctors} />
                </TabPane>
                <TabPane tab="شركات التأمين" key="3">
                    <div className="doctor-info">
                        <div className="left">
                            <div className="">
                                <div className="doctor-header">
                                    <img src={process.env.PUBLIC_URL + '/assets/images/examine.svg'} alt="" />
                                    <p>شركات التأمين</p>
                                </div>
                                <div className="specialties">
                                    {doctors?.insuranceCompanies.map(element => (
                                        <span className="">{element.name}</span>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </TabPane>
            </Tabs>
        </div>
    )

}

export default MedicalInfo;