import './filter-component.scss';
import { Collapse } from 'antd';
import { Checkbox, Radio } from 'antd';
import { subMedicalSpecialtiesApi, titlesApi } from '../../../../data/api/search-api';
import { useEffect, useState } from 'react';
import { Slider } from 'antd';


const { Panel } = Collapse;

const FilterComponent = ({specialtyId, changeFilter, viewDrawer}) => {
    let minValue = 1;
    let maxValue = 1000;
    let timer = null;
    const [ subMedicalArray, setSubMedicalArray ] = useState([]);
    const [ titlesArray, setTitlesArray ] = useState([]);
    const [ dateArray, setDateArray ] = useState([]);
    const [ subMedicalSpecialties, setSubMedicalSpecialties ] = useState([]);
    const [ titles, setTitles ] = useState([]);
    const [ gender, setGender ] = useState('');
    const [ allDaysDate, setAllDaysDate ] = useState(false);
    const [ price, setPrice ] = useState([minValue, maxValue])

    const marks = {
        0: '0',
        1000: '1000'
    };

    useEffect(() => {
        geTitles();
    }, [])
    
    useEffect(() => {
        getSubMedicalSpecialties();
    }, [specialtyId])

    const getSubMedicalSpecialties = () => {
        subMedicalSpecialtiesApi({medical_specialty_id: specialtyId}).then(
            res => {
                setSubMedicalSpecialties(res.data.data)
            }
        )
    }

    const geTitles = () => {
        titlesApi().then(
            res => {
                setTitles(res.data.data);
            }
        )
    }

    const onChangeSubMedical = (event, id) => {
        if (event.target.checked) {
            subMedicalArray.push(id);
            setSubMedicalArray(subMedicalArray);
        } else {
            subMedicalArray.splice(subMedicalArray.indexOf(id), 1);
            setSubMedicalArray(subMedicalArray);
        }
        changeFilter(subMedicalArray, dateArray, allDaysDate, gender, titlesArray, price);
    }

    const onChangeTitle = (event, id) => {
        if (event.target.checked) {
            titlesArray.push(id);
            setTitlesArray(titlesArray);
        } else {
            titlesArray.splice(titlesArray.indexOf(id), 1);
            setTitlesArray(titlesArray);
        }
        changeFilter(subMedicalArray, dateArray, allDaysDate, gender, titlesArray, price);
    }

    const onChangeGender = (event) => {
            setGender(event.target.value);
            changeFilter(subMedicalArray, dateArray, allDaysDate, event.target.value, titlesArray, price);
    }

    const onChangeDate = (event, value) => {
        if (event.target.checked && value === 'all') {
            setDateArray([]);
            changeFilter(subMedicalArray, dateArray, true, gender, titlesArray, price);
            setAllDaysDate(true)
            return;
        } else if (event.target.checked) {
            dateArray.push(formatDate(new Date(value)))
            setDateArray(dateArray);
        } else {
            setDateArray(dateArray.splice(dateArray.indexOf(value), 1));
        }
        changeFilter(subMedicalArray, dateArray, allDaysDate, gender, titlesArray, price);
    }

    const onPriceChange = (value) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            setPrice(value);
            changeFilter(subMedicalArray, dateArray, allDaysDate, gender, titlesArray, value);
        }, 700)
    }

    const formatDate = (date) => {
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        return `${year}-${month}-${day}`;
    }

    return(
        <div className={viewDrawer ? "active filter-component" : "filter-component"}>
            <div className="title">
                <h3 className="">حدد بحثك</h3>
            </div>
            <div className="">
                <Collapse bordered={false} defaultActiveKey={['1']}>
                    <Panel header="التخصصات الفرعية" key="1">
                        <div className="data">
                            {subMedicalSpecialties.length ? subMedicalSpecialties.map(element => (
                                <Checkbox key={element.id} className="checkbox" onChange={(event) => onChangeSubMedical(event, element.id)}>{element.name}</Checkbox>
                            )) : <p style={{'textAlign': 'center'}}>اختر تخصص</p>}
                            {/* <p className="get-all">عرض الكل <DownOutlined /></p> */}
                        </div>
                    </Panel>
                </Collapse>
            </div>
            <div className="">
                <Collapse bordered={false} defaultActiveKey={['2']}>
                    <Panel header="المواعيد المتاحة" key="2">
                        <div className="data">
                            <Checkbox className="checkbox" onChange={(event) => onChangeDate(event, new Date())}>اليوم</Checkbox>
                            <Checkbox className="checkbox" onChange={(event) => onChangeDate(event, new Date().setDate(new Date().getDate() + 1))}>غدا</Checkbox>
                            <Checkbox className="checkbox" onChange={(event) => onChangeDate(event, 'all')}>أى يوم</Checkbox>
                        </div>
                    </Panel>
                </Collapse>
            </div>
            {/* <div className="">
                <Collapse bordered={false} defaultActiveKey={['3']}>
                    <Panel header="الجنس" key="3">
                        <div className="data">
                        <Radio.Group onChange={onChangeGender}>
                            <Radio className="checkbox" name="gender" value="male">دكتور / ذكر</Radio>
                            <Radio className="checkbox" name="gender" value="female">دكتورة / أنثى</Radio>
                        </Radio.Group>
                        </div>
                    </Panel>
                </Collapse>
            </div> */}
            {/* <div className="">
                <Collapse bordered={false} defaultActiveKey={['4']}>
                    <Panel header="الألقاب" key="4">
                        <div className="data">
                            {titles.map(element => (
                                <Checkbox key={element.id} className="checkbox" onChange={(event) => onChangeTitle(event, element.id)}>{element.name}</Checkbox>
                            ))}
                        </div>
                    </Panel>
                </Collapse>
            </div> */}
            <div className="">
                <Collapse bordered={false} defaultActiveKey={['5']}>
                    <Panel header="سعر الكشف" key="5">
                        <div className="data">
                            <Slider range marks={marks} defaultValue={[1, 1000]} onChange={onPriceChange} min={minValue} max={maxValue} />
                        </div>
                    </Panel>
                </Collapse>
            </div>
        </div>
    )
}

export default FilterComponent;