import axios from "./base-api";

export const getAllOffersApi = (search, page) => {
    let query = `?page=${page}`;

    if (search) {
        query += `&search=${search}`;
    }
    return axios({
        method: 'get',
        url: `/offers${query}`,
    })
}

export const getOfferDetailsAPi = (id) =>
    axios({
        method: 'get',
        url: `/offers/${id}`
    })
