import React from 'react';
import { useParams } from "react-router-dom";
import './doctor-info.scss';
import { getDoctorAppointmentApi } from '../../../../data/api/search-api';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'antd';
import Login from '../../../auth/page/login/login';
import { useHistory } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import { Skeleton } from "antd";

const DoctorInfo = ({doctors, offerId, user}) => {
    const params = useParams();
    const { id } = params;
    const [appointments, setAppointments ] = useState([]);
    const [selectedTime, setSelectedTime] = useState();
    const [selectedOFfer, setSelectedOffer] = useState();
    const [showModal, setShowModal] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [appointmentsBody, setAppointmentsBody] = useState({});
    const [dayIndex, setDayIndex] = useState(0);
    const history = useHistory();
    const search = useLocation().search;
    const video = new URLSearchParams(search).get('video');
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getAppointments(video);
    }, []);

    const getAppointments = (video = false) => {
        setLoading(true)
        getDoctorAppointmentApi(id, offerId, video).then(
            (res) => {
                const appointmentArray = offerId ? res.data.data.filter(element => element.offer) : res.data.data
                setAppointments(appointmentArray)
                const result = appointmentArray.reduce(function (r, a) {
                    r[a.day] = r[a.day] || [];
                    r[a.day].push(a);
                    return r;
                }, Object.create(null));
                const resultKeys = Object.keys(result).sort();
                const resultSorted = {}
                resultKeys.forEach(key => {
                    resultSorted[key] = result[key];
                })
                setAppointmentsBody(resultSorted);
                setLoading(false)
            }
        )
    }

    const getNextDayAppointments = () => {
        if (dayIndex !== Object.keys(appointmentsBody).length -1) {
            const counter = dayIndex + 1;
            setDayIndex(counter);
        }
    }

    const getPrevDayAppointments = () => {
        if (dayIndex !== 0) {
            const counter = dayIndex - 1;
            setDayIndex(counter);
        }
    }

    const onSelectTime = (value) => {
        setSelectedTime(value)
    }

    const onSelectOffer = (value) => {
        localStorage.removeItem('offer')
        setSelectedOffer(value)
        if (value) {
            localStorage.setItem('offer', JSON.stringify(value))
        }
    }

    const onBookingDoctor = () => {
        setButtonDisabled(false)
        if (!selectedTime) {
            setButtonDisabled(true)
            return;
        }
        if (user) {
            history.push("/booking-doctor/" + selectedTime + '/' + id + `?video=${video}&offer_id=${offerId}`);
        } else {
            setShowModal(true)
        }
    }

    const closeModalHandler = () => {
        setShowModal(false);
    }

    return(
        <React.Fragment>
            <Skeleton className="" active={true} loading={loading} avatar>
            <div className="doctors-info">
                <div className="left">
                    <div className="">
                        <div className="doctor-header">
                            <img src={process.env.PUBLIC_URL + '/assets/images/info-circle.svg'} alt="" />
                            <p>نبذة عن الدكتور</p>
                        </div>
                        <div className="info">
                            <p>{doctors?.description}</p>
                        </div>
                    </div>

                    <div className="">
                        <div className="doctor-header">
                            <img src={process.env.PUBLIC_URL + '/assets/images/examine.svg'} alt="" />
                            <p> خدمات الدكتور</p>
                        </div>
                        <div className="specialties">
                            {doctors?.medical_specialties.map(element => (
                                <span>{element.name}</span>
                            ))}
                        </div>
                    </div>

                    <div className="">
                        <div className="doctor-header">
                            <img src={process.env.PUBLIC_URL + '/assets/images/examine.svg'} alt="" />
                            <p> شركات التأمين</p>
                        </div>
                        <div className="specialties">
                            {doctors?.medical_center?.insuranceCompanies.map(element => (
                                <span className="">{element.name}</span>
                            ))}
                        </div>
                    </div>
                    <div className="">
                        <div className="doctor-header">
                            <img src={process.env.PUBLIC_URL + '/assets/images/examine.svg'} alt="" />
                            <p>المركز التابع له</p>
                        </div>
                        <div className="info">
                            <div className="medical-center">
                                <img style={{"width": '50px', 'height': '50px', 'borderRadius': '50%'}} src={doctors?.medical_center?.image} alt="" />
                                <div>
                                    <p>اسم المركز الصحى</p>
                                    <p>{doctors?.medical_center?.name}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="right" style={{"paddingLeft": "0px"}}>
                    <div className="booking-info">
                        <span>معلومات الحجز</span>
                    </div>

                    <div className="booking-info online">
                        <span>أحجز موعد مع دكتور</span>
                    </div>

                    <div className="booking-info online data">
                        <img src={process.env.PUBLIC_URL + '/assets/images/money.svg'} alt="" />
                        {video == 'true' ? <span>سعر الكشف : {doctors?.video_fees} ر.س</span> : <span>سعر الكشف : {doctors?.clinic_fees} ر.س</span>}
                    </div>

                    <div className="booking-info online data">
                        <a target="_blank" href={`https://maps.google.com/?q=${doctors?.latitude},${doctors?.longitude}`}>
                            <img src={process.env.PUBLIC_URL + '/assets/images/address.svg'} alt="" />
                            <p>{doctors?.address}</p>
                        </a>
                    </div>

                    <div className="booking-info online">
                        <span style={{"fontWeight": "bold"}}>اختار موعد الحجز</span>
                        <div className="appointments">
                        {
                            Object.keys(appointmentsBody).length > 0 ?
                                <div className="header-book">
                                    <img src={process.env.PUBLIC_URL + '/assets/images/arrow-left.svg'} onClick={getPrevDayAppointments}  alt="" />
                                        {
                                            new Date(Object.keys(appointmentsBody)[dayIndex]).getDate() === new Date().getDate() &&
                                            new Date(Object.keys(appointmentsBody)[dayIndex]).getMonth() === new Date().getMonth() ?
                                            <p>اليوم</p> : <p>{Object.keys(appointmentsBody)[dayIndex]}</p>
                                        }
                                    <img src={process.env.PUBLIC_URL + '/assets/images/arrow-left.svg'} onClick={getNextDayAppointments}  alt="" />
                                
                                </div>: ''
                            }
                            <div className="timing">
                                {appointments?.length > 0  ?
                                    Object.values(appointmentsBody)[dayIndex]?.map(element => (
                                        <span
                                            className={selectedTime === element.uuid ? 'active' : ''} 
                                            onClick={() => {
                                                onSelectTime(element.uuid)
                                                onSelectOffer(element.offer)
                                            }}
                                        >
                                            {element.start_time}
                                        </span>
                                    ))
                                    :
                                    <p>لا يوجد حجوزات</p>
                                }
                            </div>
                            <div className="button">
                                <button onClick={onBookingDoctor}>
                                    احجز
                                    {/* <Link to="/booking-doctor/1">احجز</Link> */}
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <Modal title="" visible={showModal} footer={null} maskClosable={false} onCancel={closeModalHandler} width={600}>
                <Login url={`/booking-doctor/${selectedTime}/${id}`} />
            </Modal>
            </Skeleton>
        </React.Fragment>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user
    }
}

export default connect(mapStateToProps)(DoctorInfo);