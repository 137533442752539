import './bookings.scss';
import { useEffect, useState } from 'react';
import { getAllReservationApi } from '../../../../data/api/reservation-api';
import { Skeleton, Empty, Popover } from "antd";
import { useHistory } from 'react-router-dom';

const Bookings = (props) => {
    const history = useHistory();
    const [reservations, setReservations] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getReservations();
    }, [])

    const getReservations = () => {
        setLoading(true)
        getAllReservationApi().then(
            res => {
                setLoading(false)
                setReservations(res.data.data)
            }
        )
    }

    const statusDiv = (element) => {
        if (element.is_attended == 0) {
            return (<div className="status">لم يتم الحضور</div>)
        } else if(element.is_attended == 1) {
            return (<div className="status confirmed">تم تأكيد الحضور</div>)
        } else {
            return (<div className="status confirmed">بانتظار تأكيد الحضور</div>)
        }
    }

    const navigateToLab = (lab) => {
        localStorage.setItem('lab', JSON.stringify(lab));
        history.push(`/search-labs`);
    }

    const content = (doctorEmail, doctorPhone) => {
        return (
            <div>
                <p>{doctorEmail}</p>
                <p>{doctorPhone}</p>
            </div>
        )
    }


    return (
        <div className="bookings-container">
            <div className="doctor-content" style={{'paddingBottom': '20px'}}>
            <Skeleton className="" active={true} loading={loading} avatar>
            {reservations.length === 0 ? (
                <div className="">
                    <Empty
                        description={
                        <span className="font-Lsmall weight-600 text-theme-color">
                            لا يوجد حجوزات
                        </span>
                        }
                    />
                </div>
                ): 
                reservations.map(element => (
                        <div className="element">
                        <div style={{'display': 'flex', 'flexWrap': 'wrap'}}>
                            <div className="doctor-image">
                                <div className="images">
                                    <img src={element.doctor?.image} alt="" />
                                    {element.doctor?.medical_center?.image && <img className="medical-image" src={element.doctor?.medical_center?.image} alt="" /> }
                                </div>
                            </div>
                            <div className="doctor-data" style={{'flexGrow': '1'}}>
                                <div className="doctor-name">
                                    <p>{element.doctor.name}</p>
                                </div>
                                {reservations.doctor?.description ? <div className="doctor-description">
                                    <p>{element.doctor?.description}</p>
                                </div> : ''}
                                {element.doctor?.medical_specialties[0]?.name ? <div className="doctor-description">
                                    <img src={process.env.PUBLIC_URL + '/assets/images/examine.svg'} alt="" />
                                    <p>{element.doctor?.medical_specialties[0]?.name}</p>
                                </div> : ''}
                                {element.doctor?.medical_center?.name &&<div className="medical">
                                    <p>إسم المركز الصحى</p>
                                    <p>{element.doctor?.medical_center?.name}</p>
                                </div>}
                            </div>
                            <div className="doctor-address">
                                <div>
                                    <img src={process.env.PUBLIC_URL + '/assets/images/calendar.svg'} alt="" />
                                    <p>{element.day} {element.start_time}</p>
                                </div>
                                <div>
                                    <img src={process.env.PUBLIC_URL + '/assets/images/money.svg'} alt="" />
                                    <p>موعد مع دكتور: {element?.total} ر.س</p>
                                </div>
                                <div>
                                    <a target="_blank" href={`https://maps.google.com/?q=${element?.doctor?.latitude},${element?.doctor?.longitude}`}>
                                        <img src={process.env.PUBLIC_URL + '/assets/images/address.svg'} alt="" />
                                        <p>{element?.doctor?.address}</p>
                                    </a>
                                </div>
                                <div className="options-container">
                                    {element.analysis_count > 0 && <div onClick={() => navigateToLab(element)} className="status" style={{backgroundColor: '#3b0a80', color: '#FFF', cursor: 'pointer'}}>
                                        حجز التحاليل
                                    </div> }
                                    {statusDiv(element)}
                                    {element.status != 4 ?<div className="options">
                                        { element.visit_type !== 2 ? 
                                            <div>
                                                <a style={{flexDirection: 'column'}} target="_blank" href={`https://maps.google.com/?q=${element?.doctor?.latitude},${element?.doctor?.longitude}`}>
                                                    <img src={process.env.PUBLIC_URL + '/assets/images/map.svg'} alt="" />
                                                    <p>الخريطة</p>
                                                </a>
                                            </div> :
                                            <div onClick={() => history.push('/video-channel')} style={{cursor: 'pointer'}}>
                                                <img src={process.env.PUBLIC_URL + '/assets/images/telephone.png'} alt="" />
                                                <p>بدء المكالمة</p>
                                            </div>
                                        }
                                        {/* <div>
                                            <img src={process.env.PUBLIC_URL + '/assets/images/cancel.svg'} alt="" />
                                            <p>إلغاء</p>
                                        </div> */}
                                        {/* <div onClick={() => window.location.replace("/#contactUs")}>
                                            <Popover style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}} content={() => content(element.doctor?.email, element.doctor?.mobile)}>
                                                <img src={process.env.PUBLIC_URL + '/assets/images/help.svg'} alt="" />
                                                <p>طلب مساعدة</p>
                                            </Popover>
                                        </div> */}
                                    </div> : <div className="options cancel-option">
                                        <div>
                                            <p className="cancel">تم الإلغاء</p>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                ))
                }
                </Skeleton>
            </div>
        </div>
    )
}

export default Bookings;