import './medical-history.scss';
import { useEffect, useState } from 'react';
import { getReservationReportsApi, showReservationReportApi } from '../../../../data/api/reservation-api';
import { Skeleton, Empty } from "antd";

const MedicalHistory = () => {
    const [doctors, setDoctors] = useState([]);
    const [skeletonLoading, setSkeletonLoading] = useState(false);
    const [showDetails, setShowDetails] = useState(true);
    const [selectedDoctor, setSelectedDoctor] = useState({});

    useEffect(() => {
        getReservationReports();
    }, [])

    const getReservationReports = () => {
        setSkeletonLoading(true);
        getReservationReportsApi().then(
            res => {
                setSkeletonLoading(false);
                setDoctors(res.data.data)
            }
        )
    }

    const getReportData = (doctorId) => {
        setShowDetails(false);
        setSkeletonLoading(true);
        showReservationReportApi(doctorId).then(
            res => {
                setSelectedDoctor(res.data.data);
                setSkeletonLoading(false);
            }
        )
    }

    return (
        <div className="medical-history">
            <Skeleton className="" active={true} loading={skeletonLoading} avatar>
                {showDetails && (doctors.length ? doctors?.map((doctor, index) => (
                <div key={index} className="medical-card" onClick={() => getReportData(doctor.uuid)}>
                    <div className="medical-header">
                        <h3>تقرير الدكتور</h3>
                        <p>{doctor.created_at}</p>
                    </div>
                    <div className="medical-data">
                        <img src="" alt="" />
                        <div>
                            <p>{doctor.doctor?.name}</p>
                            <span>{doctor.description}</span>
                        </div>
                    </div>
                </div>)) : <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', margin: '30px 0'}}>
                    <Empty
                        description={
                        <span className="font-Lsmall weight-600 text-theme-color">
                            لا يوجد تقارير
                        </span>
                        }
                    />
                </div>)}
                {!showDetails && 
                    <div className="lab-details-container">
                        <div className="lab-header">
                            <img src={process.env.PUBLIC_URL + '/assets/images/lab-arrow.svg'} alt="" onClick={() => setShowDetails(true)} />
                            <p>تقرير الدكتور</p>
                        </div>
                        <div class="lab-details">
                            <div className="lab-image">
                                <div>
                                    <img src={selectedDoctor?.medical_center?.image} alt="" />
                                </div>
                                <div className="lab-name">
                                    <h3>{selectedDoctor?.medical_center?.name}</h3>
                                    <p>{selectedDoctor?.medical_center?.description}</p>
                                </div>
                                <div className="lab-doctor">
                                   <div>
                                    <img src={selectedDoctor?.doctor?.image} alt="" />
                                   </div>
                                    <div>
                                        <h3>{selectedDoctor?.doctor?.name}</h3>
                                        <p>{selectedDoctor?.doctor?.description}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="lab-address">
                                <div className="lab-calendar">
                                    <img src={process.env.PUBLIC_URL + '/assets/images/lab-calendar.svg'} alt="" />
                                    <p>{selectedDoctor.created_at}</p>
                                </div>
                                <div>
                                    <a target="_blank" href={`https://maps.google.com/?q=${selectedDoctor?.doctor?.latitude},${selectedDoctor?.doctor?.longitude}`}>
                                        <img src={process.env.PUBLIC_URL + '/assets/images/address.svg'} alt="" />
                                        <p>{selectedDoctor?.doctor?.address}</p>
                                    </a>
                                </div>
                            </div>
                        </div>
                        {selectedDoctor.notes && <div className="lab-report">
                            <div>
                                <img src={process.env.PUBLIC_URL + '/assets/images/medical-history.svg'} alt="" />
                                <p>التقرير الطبى</p>
                            </div>
                            <p>{selectedDoctor.notes}</p>
                        </div>}
                        <div className="lab-result">
                            {selectedDoctor.analysis.length &&<div className="lab-analysis">
                                <div>
                                    <img src={process.env.PUBLIC_URL + '/assets/images/lab-result.svg'} alt="" />
                                    <p>التحاليل</p>
                                </div>
                                <div>
                                    {selectedDoctor.analysis.map((item, index) => (<span key={index}>{item.name}</span>))}
                                </div>
                            </div>}
                            {selectedDoctor.medicines.length && <div className="lab-medicine">
                                <div>
                                    <img src={process.env.PUBLIC_URL + '/assets/images/medicine.png'} alt="" />
                                    <p>الأدوية</p>
                                </div>
                                <div>
                                    {selectedDoctor.medicines.map((item, index) => (<span>{item.name} <span>{item.description}</span></span>))}
                                </div>
                            </div>}
                        </div>
                    </div>
                }
            </Skeleton>
        </div>
    )
}

export default MedicalHistory;