import React from 'react';
import { Switch } from 'react-router-dom';

import Register from '../ui/auth/page/register/register';
import LoginWrapper from '../ui/auth/page/login/login';
import Handle404 from '../shared/component/404/404';
import Route from './index';
import SearchResult from '../ui/search-result/page/search-result';
import ViewDoctor from '../ui/view-doctor/page/view-doctor';
import Home from '../ui/home/page/home';
import BookingDoctor from '../ui/booking-doctor/booking-doctor';
import MyAccount from '../ui/my-account/page/my-account';
import SearchMedical from '../ui/search-medical/page/search-medical';
import ViewMedicalCenter from '../ui/view-medical-center/page/view-medical-center';
import VideoCall from '../ui/video-call/video-call';
import PaymentProcess from '../ui/booking-doctor/payment-process';
import LabsResult from '../ui/labs-result/page/labs-result';
import ViewLab from '../ui/view-lab/page/view-lab';
import BookingLab from '../ui/booking-lab/booking-lab';
import BookingCart from '../ui/booking-cart/booking-cart';
import WhoAre from '../ui/home/page/who-are/who-are';
import TermsConditions from '../ui/home/page/terms-conditions/terms-conditions';
import OffersList from '../ui/offers/offers-list';
import OfferDetails from '../ui/offer-details/offer-details';
import Discount from '../ui/discount/discount';

const Routes = () => {
    return (
        <Switch>
            <Route path="/home" component={Home} />
            <Route path="/search-doctor" component={SearchResult} />
            <Route path="/search-labs" component={LabsResult} />
            <Route path="/search-medical" component={SearchMedical} />
            <Route path="/video-call" component={SearchResult} />
            <Route path="/medical-center/:id" exact component={ViewMedicalCenter} />
            <Route path="/doctor/:id" exact component={ViewDoctor} />
            <Route path="/doctor/:id/:offerId" exact component={ViewDoctor} />
            <Route path="/lab/:id" exact component={ViewLab} />
            <Route path="/lab/:id/:offerId" exact component={ViewLab} />
            <Route path="/booking-doctor/:timeId/:id" component={BookingDoctor} />
            <Route path="/booking-lab/:id" component={BookingLab} />
            <Route path="/booking-cart/:id" component={BookingCart} />
            <Route path="/my-account" component={MyAccount} />
            <Route path="/about-us" component={WhoAre} />
            <Route path="/terms" component={TermsConditions} />
            <Route path="/login" component={LoginWrapper} />
            <Route path="/register" component={Register} />
            <Route path="/video-channel" component={VideoCall} />
            <Route path="/payment-process/:checkout_id/:method" component={PaymentProcess} />
            <Route path="/offers" component={OffersList} />
            <Route path="/offer/:id" component={OfferDetails} />
            <Route path="/discount" component={Discount} />
            <Route path="/" exact component={Home} />
            <Route component={Handle404} />
        </Switch>
    )
}

export default Routes;